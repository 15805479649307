import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, TextField } from "@mui/material";
import { validate } from "../arabic/wordValidator";
import { validateWord } from "../api/wordSlice";
import { useDispatch, useSelector } from "react-redux";
import { convertToSpecialCharacter } from "../arabic/characterConverter";
import { updateActiveVerse, selectActiveVerse } from "../api/versesSlice";


const VerseTextField = (props) => {
  const dispatch = useDispatch();
  const activeVerse = useSelector((state) => selectActiveVerse(state));
  const [verseId] = useState(props.verse.id);

  const handleSpecialCharacter = (e) => {
    e.target.value = convertToSpecialCharacter(e.target.value);
  };


  const handleValidation = (verse, value) => {
    const words = validate(verse, value);
    if (words.validation === "valid" && activeVerse.id === props.verse.id) {
      dispatch(updateActiveVerse());
    }
    dispatch(validateWord(words.words));
  }
  const handleChange = (e) => {
    
      handleValidation(props.verse, e.target.value);

  };

  return (
      <TextField
        inputProps={{
          dir: "rtl",
          style: { fontSize: 40 },
          //ref: ref
        }}
        inputRef={props.inputRef}
        size="small"
        id={"veses-" + verseId}
        variant="outlined"
        sx={{ width: 1, mb: 3 }}
        onChange={handleSpecialCharacter}
        onKeyPress={handleChange}
        //onBlur={(e) => handleValidation(props.verse, e.target.value)}
      />

  );
};
export default VerseTextField;
