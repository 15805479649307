import { createSlice } from "@reduxjs/toolkit";

const activeVersesStyle = {
  borderRadius: 2,
  backgroundColor: "#f5fff5",
};

export const selectActiveVerse = (state) =>
  state.verses.verses[state.verses.activeVerse];

const versesSlice = createSlice({
  name: "verses",
  initialState: {
    verses: [],
    activeVerse: 0,
    style: {
      ...activeVersesStyle,
    },
  },
  reducers: {
    setVerses(state, action) {
      console.log(action.payload);
      state.verses = action.payload;
      state.activeVerse = 0;
      return state;
    },
    updateActiveVerse(state, action) {
      if (state.activeVerse !== state.verses.length - 1)
        state["activeVerse"] += 1;
      return state;
    },
    setActiveVerse(state, action) {
      const index = state.verses.findIndex((el) => el.id === action.payload);
      state.activeVerse = index;
    },
  },
});

export const { updateActiveVerse, setVerses, setActiveVerse } =
  versesSlice.actions;

export default versesSlice.reducer;
