import React, { useEffect,useRef } from "react";
import { ListItem, ListItemAvatar} from "@mui/material";
import VerseTextField from "./VerseTextField";
import Verse from "./Verse";
import { useDispatch } from "react-redux";
import { setActiveVerse } from "../api/versesSlice";

const VerseContainer = (props) => {
  
  const dispacth = useDispatch();
  const verse = props.verse;
  const activeVerse = props.activeVerse;
  const inputRef = useRef(null);

  const isActive = activeVerse.id === verse.id;
  useEffect(() => {
    if (inputRef!==null && isActive) {
        inputRef.current.focus();
      inputRef.current.scrollIntoView({block: "center", inline: "nearest"});
    }
  }, [isActive]);
  
  return (
   
    <ListItem
      /*onClick={()=>dispacth(setActiveVerse(verse.id))}*/
      id={"verse" + verse.id}
      key={"verse" + verse.id}
      
      divider
      sx={{
        display: "block",
        mb: 2,
        borderRadius: 2,
        backgroundColor: isActive ? "#f5fff5" : "white",
      }}
    ><ListItemAvatar > {verse.verse_key}</ListItemAvatar>
      <Verse verse={verse} />
      {isActive ? <VerseTextField  inputRef={inputRef} verse={verse} /> : ""}
    </ListItem>
  );
};

export default VerseContainer;
