export const validate = (verse, text_input) => {
  let words = [];
  let verseResult = {id:verse.id, validation:"valid"}
  const text_input_trim = text_input.trim();
  console.log(text_input_trim.normalize());
  console.log(verse.text_imlaei.replace(/\u{06DB}\s+/gu, "").trim().normalize());
  if (text_input_trim.normalize() === verse.text_imlaei.replace(/\u{06DB}\s+/gu, "").trim().normalize()) {
    verseResult.validation = "valid"
  } else {
    verseResult.validation = "invalid"
  }
  text_input_trim.split(' ').forEach((word_input, index) => {
    console.log(word_input);
    console.log(verse.words);
    const wordFound = verse.words.find( (word) => word.text_imlaei.replace(/[\u{06DB}-\u{06DB}]/gu, "").trim().normalize() === word_input.trim().normalize() && word.position === index + 1 );
    if (wordFound === undefined) {
      //verseResult.validation = "invalid"
      return;
    }
    if (wordFound.position !== index + 1) {
        console.log("missedPlace", verse.words[index]);
      // verse.words[index]['validation'] ='valid';
      words.push({ ...wordFound, validation: "missedPlace" });
      //verse.words[index]['validation'] ='missedPlace';
    } else {
      words.push({ ...wordFound, validation: "valid" });
      console.log("valid", verse.words[index]);
    }
  });

  return {...verseResult,words:words};
};

const validateWord = (words, text_input_word) => {
  words.forEach((word) => {
    if (word.char_type_name === "word" && word.text_uthmani === text_input_word)
      word["validation"] = "valid";
  });
};
