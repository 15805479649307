import React from "react";
import { useGetChaptersQuery } from "../api/quranApi";
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {  Link } from "react-router-dom";
const Chapters = () => {
  const { data, error, isLoading } = useGetChaptersQuery({ language: "en" });

  if (data !== undefined && !isLoading) {
    return (
      <List subheader={<ListSubheader>Surah</ListSubheader>}>
        {data.chapters.map((chapter) => (
          <ListItemButton
            key={chapter.id + chapter.name_simple}
            component={Link}
            to={{ pathname: `/write/${chapter.id}` }}
            state={{chapter:chapter}}
            dense
            alignItems="center"
          >
            <ListItemIcon>{chapter.id}</ListItemIcon>
            <ListItemText
              key={chapter.id + chapter.name_simple}
              primary={chapter.name_simple}
            />
          </ListItemButton>
        ))}
      </List>
    );
  }

  if (isLoading) {
    return "i am loading!";
  }
};

export default Chapters;
