import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Canvas } from "@react-three/fiber";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import logo from "../quranchallenge-high-resolution-logo-color-on-transparent-background.svg";
import { Link } from "react-router-dom";
import Fox from "../components/Fox";
import { Float, OrbitControls, PivotControls } from "@react-three/drei";

const LandingPage = () => {
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "Transparent",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "black",
              flexGrow: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={logo}
              style={{ height: "1.8rem", marginRight: "8px" }}
              alt="logo"
            />{" "}
            QuranQuizz
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            //onClick={handleMenu}
            color="black"
          >
            <LanguageIcon />
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            //onClick={handleMenu}
            color="black"
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="section"
        sx={{
          height: "100%",
          overflow: "hidden",
          background: "#528265",
          position: "relative",
          minHeight: "880px",
          "&::before": {
            content: '""',
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            position: "absolute",
            background: `url('./creative-shape.svg') no-repeat scroll center bottom/cover`,
            zIndex: 0,
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "48%",
            height: "100%",
            right: "10px",
            zIndex: 1000,
            top: "12%",
          }}
        >
          <Canvas
            shadows
            camera={{
              fov: 45,
              near: 0.1,
              far: 200,
              position: [-4, 3, 6],
            }}
          >
            <ambientLight intensity={0.5} />
            {/*<OrbitControls makeDefault />*/}
            <PivotControls depthTest={false}>
              <Float>
                <Fox />
              </Float>
            </PivotControls>
          </Canvas>
        </Box>
        <Container>
          <Card
            elevation={0}
            sx={{
              backgroundColor: "transparent",
              alignItems: "flex-start",
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-start",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <CardHeader
              sx={{ zIndex: 1000 }}
              title="Welcome to QuranQuizz"
              subheader="dsjfgdsjklfgsd fdsflb sdlfbdslf bsdlf sdblf sdlfkdb"
            />
            <CardContent sx={{ zIndex: 1000 }}>fdgdfg</CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#528265" }}
                component={Link}
                to={{ pathname: `/write` }}
              >
                {" "}
                Start >
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
