import { createSlice,current } from "@reduxjs/toolkit";

const initialWordStyle = {
  //visibility: 'hidden'
};
const valideWordStyle = {
  color: "green",
};
const missedPlacedWordStyle = {
  color: "orange",
};

export const selectWordById = (state, id) => state.words.find((word) => word.id === id)

const wordsSlice = createSlice({
  name: "words",
  initialState: [],
  reducers: {
    addWords(state, action) {
      action.payload.forEach((element) => {
        const word = state.find((word) => word.id === element.id);
        if (word === undefined ) {
          state.push({ ...element, style: element.char_type_name==="word"? initialWordStyle:{ } });
        }
      });
    },
    wordAdded(state, action) {
      console.log(action);
      state.push({ ...action.payload, style: initialWordStyle });
    },
    wordDeleted(state, action) {
      return state.filter((word) => word.id !== action.payload);
    },
    validateWord(state, action) {
      action.payload.forEach((word) => {
        
        console.log(current(state))
        console.log(word);
        const currentWord = state.findIndex((stateWord) => stateWord.id === word.id);
        console.log(currentWord !== 'undefined' &&  (word.validation === "valid"))
      
        if (currentWord !== 'undefined' &&  (word.validation === "missedPlace")) {
          state[currentWord].style =  {...missedPlacedWordStyle};
        }
        if (currentWord !== 'undefined' &&  (word.validation === "valid")) {

          state[currentWord].style = {...valideWordStyle};
        }
      })
      return state;
    },
  },
});

export const { addWords, wordAdded, wordDeleted, validateWord } =
  wordsSlice.actions;

export default wordsSlice.reducer;
