import "./App.css";
import Pagesv2 from "./components/Pagesv2";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "./screen/Main";
import LandingPage from "./screen/LandingPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/write",
    element: <Main />,
    children: [
      {
        path: "/write/:chapterId",
        element: <Pagesv2 />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
