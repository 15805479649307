import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { quranApi } from "./quranApi";
import wordsReducer, { addWords } from "./wordSlice";
import versesReducer,{ setVerses } from "./versesSlice";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    if (
      (action.type === "quranApi/executeQuery/fulfilled") &
      (action.meta?.arg?.endpointName === "getByChapter")
    ) {
      return true;
    }
  },
  effect: async (action, listenerApi) => {
    console.log(action);
    console.log(listenerApi);
    let words = [];
    let verses = [];

    action.payload.verses.forEach((element) => {
      //verses = verses.push({id:element.id, verse_number: element.verse_number, verse_key: element.verse_key});
      words = words.concat(element.words);
    }); 
    listenerApi.dispatch(setVerses(action.payload.verses));
    listenerApi.dispatch(addWords(words));
    //
  },
});

const rootReducer = combineReducers({
  [quranApi.reducerPath]: quranApi.reducer,
  words: wordsReducer,
  verses:versesReducer 
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat(quranApi.middleware),
    preloadedState,
  });
};
