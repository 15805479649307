import React from "react";
import Word from "./Word";
import { Box } from "@mui/material";

const Verse = (props) => {
  return (
    <Box
      
      sx={{
        display: "inline-flex",
        flexDirection: "row-reverse",
        width: 1,
        mb:3,
        flexWrap: "wrap",
      }}
    >
      {props.verse.words.map((word) => (
        <Word key={word.id} word={word} />
      ))}
    </Box>
  );
};

export default Verse;
