import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetByChapterQuery } from "../api/quranApi";
import {
  Pagination,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  List,
  ListSubheader,
  Box,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveVerse } from "../api/versesSlice";
import useFont from "../utils/useFont";
import VerseContainer from "./VerseContainer";
import { SvgIcon } from "@mui/material";
import { ReactComponent as bismillahSvg } from "../assets/bismillah.svg";
const Pagesv2 = () => {
  let params = useParams();
  let location = useLocation();
  let [page, setPage] = useState(1);
  useFont(location.state.chapter);
  const activeVerse = useSelector((state) => selectActiveVerse(state));
  const { data, error, isLoading } = useGetByChapterQuery({
    chapterId: params.chapterId,
    language: "en",
    words: true,
    fields: "text_uthmani,text_imlaei",
    word_fields: "text_uthmani,code_v2,text_imlaei",
    page: page,
  });

  if (data !== undefined && !isLoading) {
    console.log(data.verses);
    return (
      <Card>
        <CardHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "space-around",
            flexWrap: "wrap",
          }}
          //titleTypographyProps={{ sx: { textAlign: "center" } }}
          //subheaderTypographyProps={{ sx: { textAlign: "center" } }}
          title={
            <Box
            /*sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}*/
            >
              <Typography
                variant="h2"
                style={{
                  display: "inline",
                  fontFamily: "surahnames",
                }}
              >
                 {location.state.chapter.id.toString().padStart(3, '0')}
              </Typography>
              <Typography
                variant="h2"
                style={{
                  display: "inline",
                  fontFamily: "surahnames",
                }}
              >
                surah
              </Typography>
            </Box>
          }
          subheader={
            location.state.chapter.bismillah_pre ? (
              <SvgIcon
                /*sx={{display:"inline-flex", justifyContent:'center'} }*/ component={
                  bismillahSvg
                }
              />
            ) : (
              ""
            )
          }
        />
        <CardContent>
          <List
          >
            {data.verses.map((verse, index) => {
              return (
                <VerseContainer
                  key={"verse" + verse.id}
                  verse={verse}
                  activeVerse={activeVerse}
                />
              );
            })}
          </List>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }} disableSpacing>
          <Pagination
            page={page}
            sx={{ justifyContent: "center" }}
            count={data.pagination.total_pages}
            onChange={(e, p) => console.log(setPage(p))}
          />
        </CardActions>
      </Card>
    );
  }
  if (isLoading) {
    return "i am loading!";
  }

  if (error) {
    return "error";
  }
};

export default Pagesv2;
