import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Chapters from "../components/Chapters";
import { Link, Outlet } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import logo from "../quranchallenge-high-resolution-logo-color-on-transparent-background.svg";

const drawerWidth = 200;

export function Main() {
  const [open, setOpen] = React.useState(false);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "8px 8px 8px #528265",
          backgroundColor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => setOpen(!open)}
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            color="black"
          >
            <MenuIcon />
          </IconButton>
          <Typography

            variant="h6"
            component={Link}
            to={{ pathname: `/` }}
            sx={{
              textDecoration:"none",
              color: "black",
              flexGrow: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={logo}
              style={{ height: "1.8rem", marginRight: "8px" }}
              alt="logo"
            />{" "}
            QuranQuizz
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            //onClick={handleMenu}
            color="black"
          >
            <LanguageIcon />
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            //onClick={handleMenu}
            color="black"
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        transitionDuration= {{ appear: 1000,enter: 1000, exit: 1000 }}
        open={open}
        sx={{
          width: drawerWidth ,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width:  drawerWidth ,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Chapters />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
