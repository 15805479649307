import React from "react";
import { Typography } from "@mui/material";
import { getFontFaceNameForPage } from "../utils/FontForPageUtils";
import { useSelector } from 'react-redux'
import { selectWordById } from "../api/wordSlice";

const Word = (props) => {

  const fontFamily = getFontFaceNameForPage(false, props.word.page_number);
  const wordStyle = useSelector((state) => selectWordById(state, props.word.id))
  let audio = new Audio("https://verses.quran.com/"+props.word.audio_url);
 
  return (
    <Typography
      key={"word"+props.word.id}
      onClick={ props.word.char_type_name ==="word"? () => audio.play() : ()=>{}} 
      variant="h2"
      data-font="code_v2"
      style={{
        fontFamily: fontFamily, cursor: "pointer",
        ...wordStyle.style
      }}
    >
      {props.word.code_v2}
    </Typography>
  );
};

export default Word;
