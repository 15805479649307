import { useAnimations, useGLTF } from "@react-three/drei";
import React from "react";
import { useEffect } from "react";
import { useControls } from "leva";

const Fox = () => {
  const fox = useGLTF("./book.glb");
  const animations = useAnimations(fox.animations, fox.scene);

  const { rotation, position, animationName } = useControls({
    position: { x: -0.40, y: 1, z: 1.5 },
    rotation: { x: -2, y: 0.51, z: -4.50 },
    animationName: { options: ["Survey", "Walk", "Run"] },
  });

  /*useEffect(() => {
    const action = animations.actions.PlaneAction;
    action.play();
    console.log(action);
    /* action.reset().fadeIn(0.5).play();
   
       return () => {
           action.fadeOut(0.5)
       }
  }, []);*/

  return (
    <primitive
      object={fox.scene}
      scale={1}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
    />
  );
};

export default Fox;

useGLTF.preload("./Fox/glTF/Fox.gltf");
