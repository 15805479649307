const characterMap = [
  {
    name: "Wavy Hamza Below ",
    key: "1",
    unicode: "\u{0655}",
  },
  {
    name: "Wavy Hamza Above ",
    key: "2",
    unicode: "\u{0654}",
  },
  {
    name: "Maddah Above ",
    key: "3",
    unicode: "\u{0653}",
  },
  {
    name: "Arabic Letter Superscript Alef ",
    key: "4",
    unicode: "\u{0670}",
  },
  {
    name: "Arabic Letter Alef Wasla ",
    key: "5",
    unicode: "\u{0671}",
  },
  {
    name: "Arabic Letter Alef with Wavy Hamza Below ",
    key: "6",
    unicode: "\u{0673}",
  },
  {
    name: "Arabic Small High Three Dots",
    key: "7",
    unicode: "\u{06DB}",
  }
];

export const checkForSepicalCharcter = (string) => {
  const result = characterMap.find((chararater) => (string.includes(chararater.key))  )
  return result;
};

export const replaceKeyWithSpecialCharacter = (string, character) => {

  return string.replace(character.key, character.unicode);
};

export const convertToSpecialCharacter = (string) => {
  const character = checkForSepicalCharcter(string);
  if (character !== undefined) {
    return replaceKeyWithSpecialCharacter(string, character);
  }
 
  return string;
};

