import { useEffect } from "react";
import {
  getFontFaceNameForPage,
  getV1OrV2FontFaceSource,
  getSurahNamesFontFaceSource,
} from "./FontForPageUtils";

const useFont = (chapter) => {
  useEffect(() => {
    const fontFace = new FontFace("surahnames", getSurahNamesFontFaceSource());
    document.fonts.add(fontFace);
    fontFace.load().then(() => {
      console.log("Font faces added");
    });
  }, []);
  useEffect(() => {
    for (let i = chapter.pages[0]; i <= chapter.pages[1]; i++) {
      const fontFaceName = getFontFaceNameForPage(false, i);
      const fontFace = new FontFace(
        fontFaceName,
        getV1OrV2FontFaceSource(false, i)
      );
      document.fonts.add(fontFace);
      fontFace.load().then(() => {
        console.log("Font faces added");
      });
    }
  }, [chapter]);
};

export default useFont;
