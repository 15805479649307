export const getV1OrV2FontFaceSource = (isV1, pageNumber) => {
    const pageName = String(pageNumber).padStart(3, '0');
  
    if (isV1) {
      const woff2 = `/fonts/quran/hafs/v1/woff2/p${pageNumber}.woff2`;
      const woff = `/fonts/quran/hafs/v1/woff/p${pageNumber}.woff`;
      const ttf = `/fonts/quran/hafs/v1/ttf/p${pageNumber}.ttf`;
      return `local(QCF_P${pageName}), url('${woff2}') format('woff2'), url('${woff}') format('woff'), url('${ttf}') format('truetype')`;
    }
  
    const woff2 = `/fonts/quran/hafs/v2/woff2/p${pageNumber}.woff2`;
    const woff = `/fonts/quran/hafs/v2/woff/p${pageNumber}.woff`;
    const ttf = `/fonts/quran/hafs/v2/ttf/p${pageNumber}.ttf`;
  
    return `local(QCF2${pageName}), url('${woff2}') format('woff2'), url('${woff}') format('woff'), url('${ttf}') format('truetype')`;
  };


  export const getSurahNamesFontFaceSource = () =>{
    const woff2 = `/fonts/quran/surah-names/v1/sura_names.woff2`;
    const woff = `/fonts/quran/surah-names/v1/sura_names.woff`;
    const ttf = `/fonts/quran/surah-names/v1/sura_names.ttf`;
   return `local(QCF2surah), url('${woff2}') format('woff2'), url('${woff}') format('woff'), url('${ttf}') format('truetype')`;
    
  }
export  const getFontFaceNameForPage = (isV1, pageNumber) => isV1 ? `p${pageNumber}-v1` : `p${pageNumber}-v2`;